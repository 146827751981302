// Ecommerce
// Product
export const GET_PRODUCTS = "/products";
export const ADD_PRODUCT = "/products";
export const UPDATE_PRODUCT = "/products";
export const GET_TABLE_HEADERS = "/tables";
export const UPDATE_TABLE = "/tables";
export const GET_BRAND = "/brand";
export const ADD_BRAND = "/brand";
export const GET_VENDOR = "/product-category";
export const ADD_VENDOR = "/product-category";
export const getBrandsAkeneo = "/AkeneoBrands";
